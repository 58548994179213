<template>
  <div class="top-spacer"></div>
  <div class="page-wrapper">
    <div class="top-box">
    <h1 style="margin-top: 3rem;">Artikler og cases</h1>
    <p class="info">
      Velkommen til videnscentret for <a href="https://reklameskaerm.dk">Reklameskærm.dk</a>, hvor vi har samlet viden omkring vores medium og markedsføring generelt, samt cases der kan inspirere.
    </p>
    <p class="info">
      Artikler og cases opdateres løbende.
    </p>
    </div>

    <el-row gutter="20" v-if="loaded">
    <el-col v-for="(item) in articleAndCases" :key="item.id" :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
        <standard-card 
            :articleID="item.articleID" 
            :caseID="item.caseID" 
            :cardImageUrl="item.cardImageUrl" 
            :title="item.title" 
            :shortDescrition="item.shortDescription" 
            :createdDate="item.publishedAt">
        </standard-card>
        </el-col>
  </el-row>
  </div>
  

</template>

<script lang="ts">
import StandardCard from '../components/layouts/StandardCard.vue';
import axios from 'axios';
import qs from 'qs';

export default {
  name: 'ArticlesAndCases',
  components: { 
    StandardCard
  },
  data() {
    return {
        test: "Test",
        articleAndCases: [],
        loadedCases: false,
        loadedArticles: false,
      };
    },
    mounted() {
        this.getArticlesAndCases();
    },
    computed: {
        loaded() {
            return this.loadedArticles && this.loadedCases
        }
    },
    methods: {
        getArticlesAndCases() {
            var query = qs.stringify({
              populate: '*'
          });
          axios.get(`${process.env.VUE_APP_STRAPI_API_URL}cases/?${query}`, )
          .then(response => {
              this.addModelsToList(response.data.data, 2);
              this.loadedCases = true;
          })
          axios.get(`${process.env.VUE_APP_STRAPI_API_URL}articles/?${query}`, )
          .then(response => {
              this.addModelsToList(response.data.data, 1);
              this.loadedArticles = true;
          })
        },
        addModelsToList(strapiModels, type) {
            for(var i = 0; i < strapiModels.length; i++) {
                var strapiModel = strapiModels[i].attributes;
                var model = {
                    articleID: type == 1 ? strapiModel.HumanizedLink : '',
                    caseID: type == 2 ? strapiModel.HumanizedLink : '',
                    cardImageUrl: strapiModel.Media.data[0].attributes.formats.small.url,
                    title: strapiModel.Name,
                    shortDescription: strapiModel.ShortDescription,
                    publishedAt: strapiModel.publishedAt,
                    date: Date.parse(strapiModel.publishedAt)
                }
                this.articleAndCases.push(model);
            }
            this.articleAndCases = this.articleAndCases.sort((a, b) => b.date - a.date)
        }
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 768px) {
    /* For mobile phones: */
    .page-wrapper {
      border-top: 1px solid rgb(204 204 204);
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .page-wrapper {
      width: 95% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .page-wrapper h2 {
      padding-left: 20px;
    }
    .top-spacer {
      height: 50px !important;
    }
}

.top-spacer {
  width: 100%;
  height: 80px;
}

.page-wrapper {
  line-height: 25px;
  width: 100%;
  max-width: 1488px;
  margin: 0 auto;
  padding-left: 20px;
  padding-bottom: 40px;
}

.top-box {
  padding-left: 10px;
}


</style>
