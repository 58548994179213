<template>
  <el-card id="el-card-body" :body-style="{ padding: '0px 0px 0px 0px !important' }">
    <img
          :src="cardImageUrl"
          class="image"
    />
    <div style="padding: 14px;">
          <span class="title">{{ title }}</span>
          <div class="shortDescription">
            {{ shortDescrition }}
          </div>
          <div class="bottom">
            <time class="time">{{ formattedDate }}</time>
            <el-button text class="button" @click="openLink()">Læs mere</el-button>
          </div>
        </div>
  </el-card>

</template>

<script lang="ts">
import dayjs from 'dayjs';

export default {
  name: 'StandardCard',
  components: { 

  },
  props: {
    articleID: String,
    caseID: String,
    cardImageUrl: String,
    title: String,
    shortDescrition: String,
    createdDate: String,
  },
  methods: {
    openLink() {
      console.log("openLink: ", this.articleID, this.caseID)
      if(this.articleID !== '') {
        this.$router.push('/artikler-og-cases/artikel/' + this.articleID);
      }
      else if (this.caseID !== '') {
        this.$router.push('/artikler-og-cases/case/' + this.caseID);
      }
    },
    formatDate(dateString) {
            const date = dayjs(dateString);
            return date.format('DD/MM-YYYY');
        }
  },
  computed: {
    formattedDate() {
      return this.formatDate(this.createdDate);
    }
  }
}

</script>

<style scoped>

.title {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.shortDescription {
    margin-top:10px;
    font-size: 14px;
    overflow-y: hidden;
}

.time {
  font-size: 12px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 10px;
  min-height: auto;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 250px;
}

@media only screen and (max-width: 768px) {
    #el-card-body {
      margin-top: 1rem;
    }
}

#el-card-body {
  margin-top: 2rem;
}

</style>
